import agelist from "../utils/age-list";

export default class dateService {
  static async getAgeList() {
    try {
      let ageList = agelist;
      return ageList;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener la lista de Edades",
      };
    }
  }
}