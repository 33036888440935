<template>
  <div>

    <br>

    <v-row justify="center">
      <v-dialog v-model="dialogFamiliar" width="430px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogFamiliar = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica;font-style: normal;  font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                        font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase; ">
                      {{ info[0][indexInfo] }}</label> del Seguro Oncológico de Southbridge.
                  </label>
                </div>

              </div>


              <br>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado"> • {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico familiar.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que te diagnostiquen a ti o algún integrante de tu familia que esté contigo en el seguro
                  algún tipo de cáncer,
                  podrán iniciar el tratamiento en la Red de Salud UC CHRISTUS donde <label
                    class="textoAzul">reembolsaremos
                    hasta
                    el {{ info[1][indexInfo] }}</label> de los gastos del tratamiento por <label class="textoAzul">hasta
                    UF {{ info[3][indexInfo] }} (más de 1.000 millones de pesos)
                  </label>en el caso que el asegurado tenga menos de 69 años al momento de su diagnóstico y <label
                    class="textoAzul">UF {{ info[4][indexInfo] }} (más de 180 millones de pesos)
                  </label> caso que el asegurado tenga 70 o más años al momento de su diagnóstico.
                  <br> </label>
              </div>

              <div style="text-align: center;">
                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre </label><a
                  :href="document" target="_blank"> <br>
                  <div style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px;  ">haz clic
                    aquí</div>
                </a>
              </div>

              <br>

              <div style="padding-left: 5%;">
                <label class="encabezado"> • Indemnización Libre disposición ante
                  diagnóstico positivo.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">En caso de que te diagnostiquen a tí o algún integrante de tu familia que esté
                  contigo en el seguro algún tipo de cáncer, podrás solicitar a la compañía
                  <label class="textoAzul"> UF {{ info[5][indexInfo] }} de indemnización</label> en caso que el
                  asegurado tenga menos de 69 años al momento de su diagnóstico o
                  <label class="textoAzul"> UF {{ info[6][indexInfo] }} si tienen más de 70 años al momento de su
                    diagnóstico</label>
                  , para que lo usen en lo que quieran, como alojamientos y traslados para el tratamiento o comprarse lo
                  que necesiten.
                  <label class="textoAzul">
                    Sin preguntas</label>.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">Esto pueden solicitarlo con la confirmación de UC
                  CHRISTUS de
                  contratación y hasta de un diagnóstico positivo de cáncer.</label>
              </div>
              <br>
              <br>

              <div style=" padding-left: 5%;">
                <label class=" encabezado"> • Agrega a integrantes de tu familia sin cambiar el precio del
                  seguro.</label>
              </div>


              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">El plan {{ info[0][indexInfo] }} permite que <br> agregues a tu <label
                    class="textoAzul">cónyuge/pareja/conviviente </label>
                  y a todos tus y sus <label class="textoAzul">hijos e hijas
                  </label>(hasta
                  24 años de edad) por el <label class="textoAzul">mismo valor del plan</label>, y cada uno con la
                  protección de UF 30.000 de tratamiento y con las UF {{ info[5][indexInfo] }} de indemnización ante
                  diagnostico positivo. </label>
              </div>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado"> • Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>.
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>


            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #4F74E3; border: 2px solid #4F74E3;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogFamiliar = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIndividual" width="430px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogIndividual = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                      font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                      {{ info[0][indexInfo] }}</label> del Seguro <br> Oncológico de Southbridge.
                  </label>
                </div>

              </div>


              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico individual</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%; margin-bottom: 10px;">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás iniciar el tratamiento
                  en la Red de Salud UC
                  CHRISTUS donde reembolsaremos hasta el
                  <label class="textoAzul"> 100% de los gastos del tratamiento por hasta UF 30.000 (más de 1.000
                    millones de pesos)</label>
                  en el caso que tengas menos de 69 años al momento de tu diagnóstico y
                  <label class="textoAzul"> UF {{ info[4][indexInfo] }} (más de 180 millones de pesos)</label>
                  en caso que tengas 70 o más años al momento de tu diagnóstico. <br></label>
              </div>
              <div style="text-align: center;">
                <label class="texto">
                  Si quieres revisar más en detalle los <br> gastos que cubre<a :href="document" target="_blank"> <br>
                    <div style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px;  ">haz
                      clic
                      aquí</div>
                  </a>
                </label>


              </div>

              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • Indemnización Libre disposición ante
                  diagnóstico positivo.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás solicitar a la
                  compañía
                  <label class="textoAzul">{{ info[5][indexInfo] }} UF de indemnización</label> en caso que el asegurado
                  tenga menos de 69 años al momento de su diagnóstico o
                  <label class="textoAzul">
                    <label class="textoAzul"> UF {{ info[6][indexInfo] }} si tienen más de 70 años al momento de su
                      diagnóstico</label>
                    <label class="texto">, para que lo usen en lo que quieran, como alojamientos y traslados para el
                      tratamiento o comprarse lo que necesiten.</label>
                    Sin preguntas</label>.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">Esto puedes solicitarlo con la confirmación de UC
                  CHRISTUS de un diagnóstico positivo de cáncer.</label>
              </div>

              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>. Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #4F74E3; border: 2px solid #4F74E3;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogIndividual = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

    <div v-if="loadingPlans == false">

      <div class="planInidivual" style="text-align: center;">

        <br>

        <div v-if="planDataAux.setDesc" class="superpuesto" >
          <p class="p-desc"  style="margin-bottom: 0px !important; margin-top: 9px; padding-top: 4px; padding-left: 4px;">{{ planDataAux.valueDesc }} %  </p> 
          <p class="p-desc"  style="margin-bottom: 0px !important;font-size: 10px; padding-left: 4px;">DCTO</p> 
        </div>

        <label class="planInidivualName">{{ planData[0].name }}</label>
        <br>
        <label class="planIndividualPrecio">{{
          formatPrice(planData[0].values[0].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
            (planData[0].values[0].price).toFixed(2) }} mensual</label></label>

        <br>

        <label class="planIndividualDetalle">*Precio basado en la edad del
          contratante.</label>

        <br>

        <!-- <label v-if="verMasIndividual == false" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
            financiera</b></label> -->
        <br>

        <div v-if="verMasIndividual == true" class="text-center">

          <div class="skin-movil">

            <label class="planIndividualProtegete" style="margin-left: 6px; margin-left: 1px">
              <b><span class="line-break">Protege tu salud y tu</span><span class="line-break">tranquilidad
                  financiera</span></b>
            </label>

            <p></p>
            <!-- <p class="planIndividualCaracteristicas">Características</p>
            <p></p> -->

            <p class="planIndividualBeneficios">{{ planData[0].coverage[0].description }}</p>
            <p></p>
            <p class="planIndividualBeneficios">UF {{ ufFormat(planData[0].coverage[1].amount) }}
              {{ planData[0].coverage[1].description }}</p>
            <p></p>
            <p class="planIndividualBeneficios">UF {{ ufFormat(planData[0].coverage[2].amount) }}
              {{ planData[0].coverage[2].description }}</p>

            <br>

            <label class="planIndividualVerDetalle" @click="InfoIndividual()">Ver detalle</label>

            <br>
            <br>

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
              <v-btn :class="newElegirPlanIndividual" style="background-color: #4F74E3;" @click="next(planData[0], 0)">
                <label style="color: white;">Contratar</label>
              </v-btn>

              <br>

            </div>

          </div>

        </div>

      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
            @click="VerMasIndividual()">
            <template v-slot:actions>
              <label style="flex-direction: column; align-items: center; margin-left: -95%"
                v-if="verMasIndividual == false">Ver más</label>
              <v-icon v-if="verMasIndividual == false" style="color: white; margin-top: -5%;">
                mdi-menu-down
              </v-icon>
              <label style="flex-direction: column; align-items: center; margin-left: -75%"
                v-if="verMasIndividual == true">Ver menos</label>
              <v-icon v-if="verMasIndividual == true" style="color: white; margin-top: -5%;">
                mdi-menu-up
              </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <br>
      <br>

      <div class="planFamiliar" style="text-align: center;">

        <div class="planFamiliarRecomendado">

          <label class="planFamiliarConveniente">El Más conveniente</label>

        </div>

        <div v-if="planDataAux.setDesc" class="superpuestoFam" >
          <p class="p-descFam"  style="margin-bottom: 0px !important;padding-top: 4px; padding-left: 4px;">{{ planDataAux.valueDesc }} %  </p> 
          <p class="p-descFam"  style="margin-bottom: 0px !important;font-size: 10px;padding-left: 4px;">DCTO</p> 
        </div>

        <label class=" text-center planFamiliarName">{{ planData[1].name
          }}</label>
        <br>
        <label class="planFamiliarPrecio">{{
          formatPrice(planData[1].values[0].priceCLP) }}*
          <label class="planFamiliarSubPrecio">/ UF {{ (planData[1].values[0].price).toFixed(2) }}
            mensual</label></label>

        <br>

        <label class="planFamiliarDetalle">*Precio basado en la edad del
          contratante.</label>


        <br>
        <!-- 
        <label v-if="verMasFamiliar == false" class="planFamiliarSubDetalle"><b>¡Asegura
            hasta 7 familiares sin costo adicional!</b></label> -->

        <label v-if="verMasFamiliar == true" class="planFamiliarSubDetalle" style="margin-left: -5px;">
          <b><span class="line-break">¡Asegura hasta 7 familiares</span><span class="line-break">sin costo
              adicional!</span></b>
        </label>

        <br>

        <div v-if="verMasFamiliar == true" class="text-center">

          <div class="skin-movil">

            <p></p>
            <!-- <p class="planFamiliarCaracteristicas">Características</p>
            <p></p> -->

            <p class="planFamiliarBeneficios">{{ planData[0].coverage[0].description }}</p>
            <p></p>
            <p class="planFamiliarBeneficios">UF {{ ufFormat(planData[1].coverage[1].amount) }}
              {{ planData[0].coverage[1].description }}</p>
            <p></p>
            <p class="planFamiliarBeneficios">UF {{ ufFormat(planData[1].coverage[2].amount) }}
              {{ planData[0].coverage[2].description }}</p>
            <p></p>
            <p class="planFamiliarBeneficios">Agrega a tu cónyuge, pareja, conviviente y a todos tus hijos por un único
              precio.</p>

            <br>

            <label class="planFamiliarVerDetalle" @click="InfoFamiliar()">Ver detalle</label>

            <br>
            <br>

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
              <v-btn :class="newElegirPlanFamiliar" style="background-color: #4F74E3;" @click="next(planData[1], 1)">
                <label style="color: white;">Elegir plan</label>
              </v-btn>
            </div>

            <br>

          </div>

        </div>
      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
            @click="VerMasFamiliar()">
            <template v-slot:actions>
              <div>
                <label style="flex-direction: column; align-items: center; margin-left: -95%"
                  v-if="verMasFamiliar == false">Ver más</label>
                <v-icon v-if="verMasFamiliar == false" style="color: white; margin-top: -5%;">
                  mdi-menu-down
                </v-icon>
                <label style="flex-direction: column; align-items: center; margin-left: -75%"
                  v-if="verMasFamiliar == true">Ver menos</label>
                <v-icon v-if="verMasFamiliar == true" style="color: white; margin-top: -5%;">
                  mdi-menu-up
                </v-icon>
              </div>
            </template>

          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>

  </div>
</template>

<script>
import ShieldService from "@/services/shield.service.js";

import CryptoJS from 'crypto-js';

export default {
  name: "Plan",
  props: ["planData", "userData", "loadingPlans", "planDataAux", "servicesData", "plansOutDesc"],
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,
      newElegirPlanIndividual: 'elegirPlan',
      newElegirPlanFamiliar: 'elegirPlan',
      stepperColor: '#4F74E3',
      verMasIndividual: false,
      verMasFamiliar: false,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      formatterUF: new Intl.NumberFormat("es-CL"),
      e1: 3,
      info: [['Isapre Individual', 'Isapre Familiar', 'Fonasa Individual', 'Fonasa Familiar'], ['100%', '100%', '100%', '100%'], ['50', '50', '50', '50'], ['30.000', '30.000', '30.000', '30.000'], ['5.000', '5.000', '5.000', '5.000'], ['50', '50', '50', '50'], ['10', '10', '10', '10'], ['0', '30.000', '0', '30.000'], ['0', '50', '0', '50']],
      indexInfo: '0',
      dialogIndividual: false,
      dialogFamiliar: false,
      document: null,
      activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
      ],
    }
  },
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    ufFormat(price) {
      return this.formatterUF.format(price);
    },
    VerMasIndividual() {
      if (this.verMasIndividual == false) {
        this.verMasIndividual = true
        this.newElegirPlanIndividual = 'elegirPlan2'
      } else {
        this.verMasIndividual = false
        this.newElegirPlanIndividual = 'elegirPlan'
      }
    },
    VerMasFamiliar() {
      if (this.verMasFamiliar == false) {
        this.verMasFamiliar = true
        this.newElegirPlanFamiliar = 'elegirPlan2'
      } else {
        this.verMasFamiliar = false
        this.newElegirPlanFamiliar = 'elegirPlan'
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    InfoFamiliar() {
      if (this.planData[1].name == 'Plan Familiar ISAPRE') {
        this.indexInfo = 1
        this.document = '/documents/ISAPRE_v2.pdf'
      }
      else if (this.planData[1].name == 'Plan Familiar FONASA') {
        this.indexInfo = 3
        this.document = '/documents/FONASA_v2.pdf'
      }
      this.dialogFamiliar = true
    },
    InfoIndividual() {
      if (this.planData[0].name == 'Plan Individual ISAPRE') {
        this.indexInfo = 0
        this.document = '/documents/ISAPRE_v2.pdf'
      }
      else if (this.planData[0].name == 'Plan Individual FONASA') {
        this.indexInfo = 2
        this.document = '/documents/FONASA_v2.pdf'
      }
      this.dialogIndividual = true
    },
    async next(plan, index) {

      this.planDataAux.plan = plan;
      this.planDataAux.planOutDesc = this.plansOutDesc[index].values[0].priceCLP;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      var namePlanAux = plan.name.split(' ');
      this.userData.typePlan = namePlanAux.includes('Familiar') ? 'Familiar' : 'Individual';

      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);

      try {

        await ShieldService.newCreateStep(this.userData, null, this.planDataAux, this.servicesData, 'Planes')

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      if (namePlanAux.includes('Familiar')) {
        this.$router.push({ path: '/og/beneficiarios' })
      } else if (!namePlanAux.includes('Familiar')) {
        this.$router.push({ path: '/og/dps' })
      }
    },
  },
};
</script>

<style scoped>
.line-break {
  display: block;
}

.labelElige {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.planInidivual {
  width: 100%;
  background: linear-gradient(219deg, #F5F6FF 0%, #DADCFB 50%), #D8D8D8;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
  min-width: 300px;
}

.planInidivualDetalle {
  background: linear-gradient(192.38deg, #C4C6FF 3.57%, #F5F6FF 107.06%), #D8D8D8;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 327px;
}

.planInidivualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #343497;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #343497;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.planIndividualDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #343497;
}

.planIndividualVerMas {
  background: #617BFB;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
}

.planIndividualProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #343497;
  /* background-color: black !important; */
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
}

.planIndividualBeneficios::before {
  content: '';
  background-image: url('../assets/images/ticket-blue.png');
  /* Ruta a tu imagen de ícono */
  background-size: cover;
  /* Ajustar la imagen para que cubra completamente el área */
  background-repeat: no-repeat;
  /* Evitar que la imagen se repita */
  position: absolute;
  width: 16px !important;
  /* Ancho del ícono */
  height: 16px !important;
  /* Altura del ícono */
  margin-left: -23px;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #4F74E3;
  border: 2px solid #4F74E3;
  border-radius: 8px;
}

.elegirPlan2 {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #4F74E3;
  border: 2px solid #4F74E3;
  border-radius: 8px;
}

.skin-movil {
  background: #FFFFFF;
  background-position: center;
  background-size: cover;
  border-color: #ECECEC;
  border: 1px solid #ECECEC;
}

.planFamiliar {
  width: 100%;
  background: linear-gradient(294.61deg, #2D387C -41.93%, #4F74E3 40.77%);
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planFamiliarRecomendado {
  display: flex;
  /* flex-direction: row;
  justify-content: center; */
  align-items: center;
  /* padding: 5px 25px;
  gap: 8px; */
  width: 136px;
  height: 22px;
  border-radius: 10px 10px 0px 0px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  background: linear-gradient(270deg, #4F74E3 -15.95%, #2D387C 40.73%, #4F74E3 115.59%);
  padding: 8px 25px;
  gap: 8px;
  width: 175px;
  height: 28px;
  border-radius: 10px 10px 0px 0px;
  margin-top: -17%;
  /* margin-left: 45%; */
  min-width: 300px;
  text-align: center;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarVerMas {
  background: #343497;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
  margin: auto;
}

.planFamiliarProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
}

.planFamiliarBeneficios::before {
  content: '';
  background-image: url('../assets/images/ticket-blue.png');
  /* Ruta a tu imagen de ícono */
  background-size: cover;
  /* Ajustar la imagen para que cubra completamente el área */
  background-repeat: no-repeat;
  /* Evitar que la imagen se repita */
  position: absolute;
  width: 16px !important;
  /* Ancho del ícono */
  height: 16px !important;
  /* Altura del ícono */
  margin-left: -23px;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

.superpuesto {
  content: 'Nuevo';
  margin-top: -11% !important;
  width: 45px !important;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  background: #4F74E3;
  border-radius: 0px 10px 0px 20px;
  margin-left: 255px;
  margin-bottom: -2px;
}

.p-desc {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin: auto;
}

.superpuestoFam {
  content: 'Nuevo';
  margin-top: -3% !important;
  width: 45px !important;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: #2D387C;
  font-size: 14px;
  font-weight: bold;
  background: #ECECEC;
  border-radius: 0px 0px 0px 20px;
  margin-left: 255px;
  margin-bottom: -2px;
}

.p-descFam {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2D387C;
  margin: auto;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div:nth-child(11)>div:nth-child(2)>div {
  border-radius: 10px 10px 10px 10px;
}

.v-expansion-panel::before {
  content: none;
}
</style>