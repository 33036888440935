<template>
  <header>
    <div style="max-width: 95%; margin:auto; margin-top: 1%">

      <div :class="[isMobile ? 'mobile-header' : 'desktop-header']">

        <div v-if="isMobile" class="mobile-logo-container" style="padding-top: 5%;">

          <router-link v-if="step != 0" :to="path">

            <v-icon style=" width: 7px; height: 14px; position: absolute; left:10%; color: rgba(35, 35, 35, 1);">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <router-link v-else :to="path">

            <v-icon @click="sitio()"
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: rgba(35, 35, 35, 1);">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <img :src="logo" alt="logo" width="130px" height="25px" class="mobile-logo">

        </div>

        <div v-else class="desktop-logo-container" style=" padding-left: 3%;">

          <img :src="logoNav" alt="Image 1" class="menu-nav"
            style="padding-top: 11px; float: right; padding-right: 34px; max-height: 49px; max-width: 50%;">

          <div>

            <router-link :to="path">

              <div v-if="step == 0">

                <v-icon @click="sitio()"
                  style=" width: 20px; height: 20px; color: #2D387C ; font-size: 40px; padding-top: 29.5px;">
                  mdi-chevron-left
                </v-icon>

                <span @click="sitio()" class="textoH3" style="margin-left: 10px;">Volver atrás</span>


              </div>

              <div v-else>

                <v-icon style=" width: 20px; height: 20px; color:#2D387C ; font-size: 40px; padding-top: 29.5px;">
                  mdi-chevron-left
                </v-icon>

                <span class="textoH3" style="margin-left: 10px;">Volver atrás</span>

              </div>

            </router-link>

          </div>

        </div>

      </div>

    </div>

    <br>

  </header>
</template>
<script>
import logo from '@/assets/SB_logo.png';
import MenuNav from '@/assets/MenuNav.png';
import logoNav from '@/assets/SB_logo.png';
export default {
  name: 'MyHeader',
  props: ['step', "userData", "beneficiaries", "typePlan"],
  data() {
    return {
      displayOptions: false,
      smallScreen: false,
      logo,
      logoNav,
      MenuNav,
      isMobile: false,
      path: '',
      screen: window.screen.width,
    };
  },
  methods: {
    sitio() {
      window.location.href = "https://www.sbseguros.cl/"
    },
    setPathReturn() {

      if (this.step == 0) {
        this.path = {
          name: ""
        }
      } else if (this.step == 1) {
        this.path = {
          path: "/og/"
        }
      } else if (this.step == 2) {
        this.path = {
          path: "/og/planes"
        }
      } else if (this.step == 3) {
        if (this.typePlan == 'Individual') {
          this.path = { path: '/og/planes' }
        } else {
          this.path = { path: '/og/beneficiarios' }
        }
      } else if (this.step == 3.2) {
        this.path = {
          path: "/og/dps"
        }
      } else if (this.step == 3.3) {
        this.path = {
          path: "/og/dps2"
        }
      } else if (this.step == 3.4) {
        this.path = {
          path: "/og/dps2"
        }
      } else if (this.step == 4) {
        this.path = {
          path: "/og/dps3"
        }
      } else if (this.step == 5) {
        if (this.typePlan == 'Individual') {
          this.path = { path: '/og/dps' }
        } else {
          this.path = { path: '/og/datos-beneficiarios' }
        }

      } else if (this.step == 6) {
        this.path = {
          path: "/og/datos-contratante"
        }
      } else if (this.step == 7) {
        this.path = {
          path: "/og/"
        }
      }
    }
  },
  mounted() {
    this.setPathReturn();

  },
};
</script>
<style lang="scss">
.desktop-header {
  height: 65px;
  background: #FFFFFF;
  border: 1.6px solid var(--AD-Primario-1, #2D387C);
  box-shadow: 0px 24px 30px rgba(0, 0, 0, 0.03);
  border-radius: 15px;

  .desktop-logo {
    width: 270px;
    height: 60px;
  }

  .desktop-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

/* Estilos para mobile */
.mobile-header {
  .mobile-logo {
    width: 143px;
    height: 35px;
    display: flex;
    flex-direction: column;
    right: 0;
    justify-content: space-around;
  }
}

.mobile-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyle {
  width: 20px;
  height: 20px;
  color: rgba(51, 51, 204, 1);
  font-size: 40px;
  padding-top: 29.5px;
}

.textoH3 {
  position: absolute;
  width: 127px;
  height: 22px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  padding-top: 23px;
  /* primary */
  color: #2D387C;
}
</style>
