<template>
  <v-app>
      <!-- <NavBar /> -->
    <v-main>
      <router-view />
    </v-main>
    <br>
    <br>
    <!-- <br>
    <br> -->
    <Footer v-if="path != '/Home' && path != '/migraciones'"/>
    <FooterMigrations v-else/>
  </v-app>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import FooterMigrations from "@/components/FooterMigrations.vue";

export default {
  components: {
    Footer,
    FooterMigrations,
    // NavBar
  },
  data: () => ({
    path: null,
  }),
  mounted() {
    this.path = window.location.pathname;
  }
};
</script>