<template>
  <div :class="footer">

    <div class="text-center">

      <br>
      <br>
      <label class="cobertura">
        Av. Presidente Riesco 5335, piso 15, Santiago.
      </label>

      <br>
      <br>

      <div v-if="screen > 800" style="display: flex; justify-content: center; margin: auto;">

        <v-col>

          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Website" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficios">
              Sitio Web
            </label>
          </div>

        </v-col>

        <v-col>
          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Facebook" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficios">
              Facebook
            </label>
          </div>
        </v-col>

        <v-col>
          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Instagram" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficios">
              Instagram
            </label>
          </div>
        </v-col>

      </div>

      <div v-else style="">

        <v-col>

          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Website" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficiosMovil">
              Sitio Web
            </label>
          </div>

        </v-col>

        <v-col>
          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Facebook" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficiosMovil">
              Facebook
            </label>
          </div>
        </v-col>

        <v-col>
          <div style="display: flex; justify-content: center; margin: auto;">
            <img :src="Instagram" alt="Image 1">
            &nbsp;&nbsp;
            <label class="textoBeneficiosMovil">
              Instagram
            </label>
          </div>
        </v-col>

        <br>
        <br>

      </div>

    </div>

  </div>
</template>

<script>
import LogoSB from "@/assets/images/LogoFooter2.png";
import Facebook from '@/assets/images/facebook.png';
import Instagram from '@/assets/images/instagram.png';
import Website from '@/assets/images/website.png'

export default {
  name: "Footer",
  data: () => ({
    LogoSB,
    Facebook,
    Instagram,
    Website,
    screen: window.screen.width,
    footer: '',
  }),
  mounted() {
    if(this.screen > 800) {
      this.footer = 'footer';
    } else {
      this.footer = 'footer2';
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.cobertura {
  font-family: Helvetica;
  font-style: normal;
  text-align: center;
  font-size: 20px;
  font-weight: 550;
  color: white;
  line-height: 16px;
  opacity: 0.75;
  margin-top: -15%;
}

@media only screen and (max-width: 767px) {
  .cobertura {
    font-family: Helvetica;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: white;
    line-height: 13px;
    opacity: 0.75;
    margin-top: -15%;
  }
}

.footer {
  height: 120px;
  background: #2D387C;
  min-height: 200px;
}

.footer2 {
  height: 120px;
  background: #2D387C;
  min-height: 350px;
}

.textoBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.01em;
  color: white;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 1%;
}

.textoBeneficiosMovil {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.01em;
  color: white;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 3%;
}
</style>