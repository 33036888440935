import ageList from "../utils/hijo-list";

export default class dateService {
  static async getHijoList() {
    try {
      let variable = ageList;
      return variable;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener la lista de Edades",
      };
    }
  }
}