import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../components/Inicio.vue";
import Planes from "../components/PlanForm.vue";
import Beneficiarios from "../components/Beneficiarios.vue";
import DPS from "../components/DPS.vue";
import DPSQ2 from "../components/DPSQ2.vue";
// import DPSQ3 from "../components/DPSQ3.vue";
import DPSExito from "../components/DPSExito.vue";
import DPSFallido from "../components/DPSFallido.vue";
import DatosBeneficiarios from "../components/DatosBeneficiarios.vue";
import DatosContratante from "../components/DatosContratante.vue";
import Migraciones from "../components/Migraciones.vue";
import Resumen from "../components/Resumen.vue";
import Erorr from "../components/Error.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/og/",
  },
  {
    path: "/documents/Articule.pdf",
  },
  {
    path: "/og/",
    name: "Inicio",
    component: Inicio,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/planes",
    name: "Planes",
    component: Planes,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/beneficiarios",
    name: "Beneficiarios",
    component: Beneficiarios,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/dps/",
    name: "DPS",
    component: DPS,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/dps2/",
    name: "DPS2",
    component: DPSQ2,
    meta: {
      title: "Southbridge - Oncologico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncologico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncologico", 
        },
      ],
    },
  },
  // {
  //   path: "/og/dps3/",
  //   name: "DPS3",
  //   component: DPSQ3,
  //   meta: {
  //     title: "Southbridge - Oncologico",
  //     metaTags: [
  //       {
  //         name: "description",
  //         content: "Southbridge - Oncologico", 
  //       },
  //       {
  //         property: "og:description",
  //         content: "Southbridge - Oncologico", 
  //       },
  //     ],
  //   },
  // },
  {
    path: "/og/dps3/",
    name: "DPS3",
    component: DPSExito,
    meta: {
      title: "Southbridge - Oncologico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncologico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncologico", 
        },
      ],
    },
  },
  {
    path: "/og/dps-fallida/",
    name: "DPSFallida",
    component: DPSFallido,
    meta: {
      title: "Southbridge - Oncologico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncologico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncologico", 
        },
      ],
    },
  },
  {
    path: "/og/datos-beneficiarios",
    name: "Datos Beneficiarios",
    component: DatosBeneficiarios,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/datos-contratante",
    name: "Datos Contratante",
    component: DatosContratante,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/resumen",
    name: "Resumen",
    component: Resumen,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/error",
    name: "Error",
    component: Erorr,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/error",
    name: "ErrorMigrate",
    component: Erorr,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/migraciones",
    name: "Migraciones",
    component: Migraciones,
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
  },
  {
    path: "/og/finish",
    name: "Exito",
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Finish.vue"),
  },
  {
    path: "/finishMigraciones",
    name: "Exitos",
    meta: {
      title: "Southbridge - Oncológico",
      metaTags: [
        {
          name: "description",
          content: "Southbridge - Oncológico", 
        },
        {
          property: "og:description",
          content: "Southbridge - Oncológico", 
        },
      ],
    },
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FinishMigraciones.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
